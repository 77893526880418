import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      Based in Charlottesville, VA
    </div>
  );
}

export default Footer;
